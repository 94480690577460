import { type MetaArgs, Outlet } from '@remix-run/react'

import { Header } from '~/components/Header'
import { ToggleAffiliateOffers } from '~/components/ToggleAffiliateOffers'
import { getSocialMetas } from '~/utils/utils'

export function meta({ location }: MetaArgs) {
  return [
    ...getSocialMetas({
      url: `https://buy.loungepair.com${location.pathname}`,
    }),
  ]
}

export const handle = {
  getSitemapEntries: () => {
    return null
  },
}

export default function Directory() {
  return (
    <div className="max-w-3xl mx-auto h-screen my-6">
      <Header />
      <h1 className="mb-1 p-4 font-bold text-h1">
        Buy access to over 900+ airport lounges
      </h1>
      <div className="mx-4 mb-8">
        <ToggleAffiliateOffers />
      </div>

      <Outlet />
    </div>
  )
}
